
type CTStatus = "Unknown status" | "Recruiting" | "Completed" | "Not yet recruiting" | "Withdrawn" | "Terminated" | "Active, not recruiting" | "Enrolling by invitation";
type CTStudyType = "Interventional" | "Observational" | "Expanded Access";
export interface CTStudy {
	ID: string;
	Condition: string;
	Title: string;
	Status: CTStatus;
	url: string;
	Locations: string[];
	Phase: string[];
	StudyType: CTStudyType;
	HasResults: boolean;
}

export type CTColumn = "ID" | "Condition" | "Title" | "Status" | "Locations";

//https://www.clinicaltrials.gov/api/query/study_fields?expr=Acute+Lymphocytic+Leukemia&fields=NCTId,Condition,BriefTitle,OverallStatus&fmt=json
interface CTStudyFieldsResponse {
	StudyFieldsResponse: {
		APIVrs: string;
		DataVrs: string;
		Expression: string;
		NStudiesAvail: number;
		NStudiesFound: number;
		MinRank: number;
		MaxRank: number;
		NStudiesReturned: number;
		FieldList: string[];
		StudyFields?: Array<{
			Rank: number;
			NCTId: string[];
			Condition: string[];
			BriefTitle: string[];
			OverallStatus: string[];
			LocationFacility: string[];
			Phase: string[];
			StudyType: string[];
			ResultsFirstSubmitDate: string[] | [];
		}>;
	}
}

export type StudiesResponse = {
	studies: CTStudy[];
	totalStudiesAvailable: number;
};


export async function findStudies(
	searchExpr:string, minRank = 1, maxRank = 20,
	fields = ["NCTId", "Condition", "BriefTitle", "OverallStatus", "LocationFacility", "Phase", "StudyType", "ResultsFirstSubmitDate"]
):Promise<StudiesResponse> {
	const url = `https://clinicaltrials.gov/api/legacy/study-fields?` + new URLSearchParams({
		expr: searchExpr,
		fields: fields.join(","),
		fmt: "xml",
		min_rnk: minRank.toString(),
		max_rnk: maxRank.toString(),
	}).toString();
	const text = await (await fetch(url)).text();
	const xml = (new DOMParser()).parseFromString(text, "application/xml");
	console.debug(`Fetched url ${url}`);
	return {
		studies: Array.from(xml.querySelectorAll("StudyFields")).map(node => ({
			ID: node.querySelector("FieldValues[Field=NCTId]")!.children[0].innerHTML,
			Condition: node.querySelector("FieldValues[Field=Condition]")!.children[0].innerHTML,
			Status: node.querySelector("FieldValues[Field=OverallStatus]")!.children[0].innerHTML as CTStatus,
			Title: node.querySelector("FieldValues[Field=BriefTitle]")!.children[0].innerHTML,
			Locations: Array.from(node.querySelector("FieldValues[Field=LocationFacility]")!.children).map(n => n.innerHTML), //TODO
			url: `https://www.clinicaltrials.gov/ct2/show/${node.querySelector("FieldValues[Field=NCTId]")!.children[0].innerHTML}`,
			Phase: Array.from(node.querySelector("FieldValues[Field=Phase]")!.children).map(n => n.innerHTML),
			StudyType: node.querySelector("FieldValues[Field=StudyType]")!.children[0].innerHTML as CTStudyType,
			HasResults: node.querySelector("FieldValues[Field=ResultsFirstSubmitDate]")!.children.length > 0,
			//If there is a results submit date then the study has results, otherwise it doesn't
		})) ?? [],
		totalStudiesAvailable: Number(xml.querySelector("NStudiesFound")!.innerHTML)
	};
}
